import React from "react"
import PropTypes from "prop-types"
import { Container, Grid } from "@material-ui/core"

const Layout = ({ children }) => {
  return (
    <main>
      <Container maxWidth="lg" style={{ background: "#282828" }}>
        <Grid container>
          <Grid item xs={12}>
            {children}
          </Grid>
          {/* <Grid item md={4}>
            <aside>
              <h1>Placeholder</h1>
            </aside>
          </Grid> */}
        </Grid>
      </Container>
    </main>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired
}

export default Layout
